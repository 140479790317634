import React from 'react';
import classNames from 'classnames';
import styles from './LoginSignup.module.scss';
import isBrowser from '../../../../../utils/browser/isBrowser';

export default function LoginSignup(props) {
  const {
    className = '',
    desktopOnly,
    isScrolled,
    isTransparent,
  } = props;
  return (
    <div className={classNames(
      styles.LoginSignup,
      className,
      {
        [styles.isDesktopOnly]: desktopOnly,
        [styles.isScrolled]: !isScrolled && isTransparent,
      }
    )}
    >
      <a
        className={ isBrowser() && window.location.href.includes('/users/login') ? styles.active : '' }
        href="/users/login"
        onClick={ () => { props.onClickNavItem('Login'); } }
      >
        Login
      </a>
      <span className={ styles.Pipe }>|</span>
      <a
        className={ isBrowser() && window.location.href.includes('/users/add') ? styles.active : '' }
        href="/users/add"
        onClick={ () => { props.onClickNavItem('Sign up'); } }
      >
        Sign up
      </a>
    </div>
  );
}
