import React, { useState } from 'react';
import styles from './TaxonomyCategoryDropDown.module.scss';
import Chevron from '../../images/chevron.svg';
import PureMinusIcon from '../../../images/atoms/PureMinusIcon/PureMinusIcon';
import PurePlusIcon from '../../../images/atoms/PurePlusIcon/PurePlusIcon';

const getChildLink = (categorySlug, activeChildSlug, slug, title) => {
  return (
    <a
      href={`/shop/${categorySlug}/${slug}`}
      className={`
        ${styles.TaxonomyCategoryLink}
        ${activeChildSlug === slug ? styles.Active : ''}
      `}
      key={slug}
    >
      {title}
    </a>
  );
};

const TaxonomyCategoryDropDown = (props) => {
  const {
    taxonomyCategories,
    currentUrl,
  } = props;

  const activeOnLoad = taxonomyCategories.filter((item) => {
    return currentUrl.indexOf(item.slug) !== -1;
  });

  const onLoadState = new Map();
  taxonomyCategories.forEach((item) => {
    onLoadState.set(item.slug, false);
  });

  const onLoadChildSlug = {
    slug: ''
  };

  if (activeOnLoad.length) {
    onLoadState.set(activeOnLoad[0].slug, true);

    const activeChildSlugOnLoad = activeOnLoad[0].children.filter((item) => {
      return currentUrl.indexOf(item.slug) !== -1;
    });

    if (activeChildSlugOnLoad.length > 0) {
      onLoadChildSlug.slug = activeChildSlugOnLoad[0].slug;
    }
  }

  const [activeSlug, setActiveSlug] = useState(onLoadState);
  const [activeChildSlug] = useState(onLoadChildSlug);

  return (
    <div className={`${styles.Link}`}>
      <a className={styles.LinkDropdownTitle} href={`/shop`}>
        Shop
        <span className={styles.Chevron}>
          <Chevron />
        </span>
      </a>

      <div className={styles.SubNav}>
        {
          taxonomyCategories
            .filter((category) => {
              return category.slug !== 'rooms';
            })
            .map((category) => {
              const isParentSlugActive = activeSlug.get(category.slug);

              return (
                <div
                  className={styles.TaxonomyCategoryMenu}
                  key={category.slug}
                >
                  <div
                    onClick={() => {
                      activeSlug.set(category.slug, !activeSlug.get(category.slug));
                      setActiveSlug(new Map(activeSlug));
                    }}
                    onKeyDown={() => {
                      activeSlug.set(category.slug, !activeSlug.get(category.slug));
                      setActiveSlug(new Map(activeSlug));
                    }}
                    className={styles.TaxonomyTitle}
                    role="button"
                    tabIndex={0}
                  >
                    <span className={`
                      ${isParentSlugActive && !activeChildSlug.slug.length ? styles.Active : ''}`}
                    >
                      {category.title}
                    </span>
                    <span className={styles.PlusIcon}>
                      {isParentSlugActive ? <PureMinusIcon /> : <PurePlusIcon />}
                    </span>
                  </div>

                  {
                    isParentSlugActive && (
                      category.children.map(({
                        title,
                        slug,
                        visibilities
                      }) => {
                        if (category.slug === 'feature') {
                          const featured = visibilities?.filter((item) => {
                            return item.merchGroupVisibilityTypeId === 1;
                          });

                          if (featured) {
                            return getChildLink(category.slug, activeChildSlug.slug, slug, title);
                          }

                          return null;
                        }

                        return getChildLink(category.slug, activeChildSlug.slug, slug, title);
                      })
                    )
                  }
                </div>
              );
            })
        }
      </div>
    </div>
  );
};

export default TaxonomyCategoryDropDown;
