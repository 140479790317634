import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import analyticsTrack from '@lib/analytics/analyticsTrack';
import { IUser } from '@models/User/i-user';
import { IRoom } from '@components/pages/Room/shared/model/Room';
import { useTheme } from '@components/theme';
import { GroupBase, SingleValue, StylesConfig } from 'react-select-latest';
import classNames from 'classnames';
import allRooms from '@graphql/Room/queries/all-rooms.graphql';
import sendContactUsMessageToCustomerExperienceTeam from '@graphql/shared/queries/send-contact-us-message.graphql';
import allUserOrders, {
  Order,
} from '@graphql/Room/queries/all-user-orders.graphql';
import { getApollo } from '@lib/apollo/apolloClient';
import formatCost from '@lib/formatCost/formatCost';
import { DateTime } from 'luxon';
import validateEmail from '@lib/validate/validateEmail';
import {
  GroupedOption,
  Option,
} from '@components/shared/GroupedOptionsSelect/model';
import GroupedOptionsSelect from '@components/shared/GroupedOptionsSelect/GroupedOptionsSelect';
import styles from './ContactUsModal.module.scss';
import CloseIcon from './close.svg';

interface IContactUsModalProps {
  visibility: boolean;
  user: IUser;
  onClose: () => void;
}

interface ContactUsPayload {
  brand: string;
  email: string;
  name: string;
  userId?: string;
  contactReason: string;
  roomName?: string;
  roomId?: string;
  orderNumber?: string;
  details: string;
}

const contactReasonOptions: GroupedOption[] = [
  {
    label: 'Design Project Support',
    options: [
      {
        value: 'Questions about my space or project',
        label: 'Questions about my space or project',
      },
      { value: 'Choosing a designer', label: 'Choosing a designer' },
      { value: 'Havenly In-Person', label: 'Havenly In-Person' },
      { value: 'Account Settings', label: 'Account Settings' },
      { value: 'Other project question', label: 'Other project question' },
    ],
  },
  {
    label: 'Merchandise Order Support',
    options: [
      { value: 'Product question', label: 'Product question' },
      { value: 'Pricing question', label: 'Pricing question' },
      { value: 'Return', label: 'Return' },
      { value: 'Cancellation', label: 'Cancellation' },
      { value: 'Status Update', label: 'Status Update' },
      { value: 'Other order question', label: 'Other order question' },
    ],
  },
  {
    label: 'Other',
    options: [{ value: 'Something Else', label: 'Something Else' }],
  },
];

const otherReasons = [
  'Other project question',
  'Other order question',
  'Something Else',
];

const sortByLastMessageCreated = (a: IRoom, b: IRoom) => {
  if (a.lastMessage.created === b.lastMessage.created) {
    return 0;
  }

  if (a.lastMessage.created === null) {
    return 1;
  }
  if (b.lastMessage.created === null) {
    return -1;
  }

  return a.lastMessage.created < b.lastMessage.created ? 1 : -1;
};

const ContactUsModal = ({
  visibility,
  user,
  onClose,
}: IContactUsModalProps) => {
  const theme = useTheme();

  const [isLoadRoomsError, setIsLoadRoomsError] = useState(false);
  const [isLoadOrdersError, setIsLoadOrdersError] = useState(false);

  const [roomOptions, setRoomOptions] = useState<GroupedOption[]>([]);
  const [orderOptions, setOrderOptions] = useState<GroupedOption[]>([]);

  const [userRooms, setUserRooms] = useState<IRoom[]>([]);
  const [userOrders, setUserOrders] = useState<Order[]>([]);

  useEffect(() => {
    async function fetchUserData() {
      if (user && visibility) {
        const apolloClient = getApollo({
          jwt: user?.jwt,
          cacheEnabled: true,
        });

        const rooms = await apolloClient.query({
          query: allRooms,
          errorPolicy: 'all',
          variables: { filters: { active: true } },
        });

        if (rooms.errors || !rooms.data) {
          setIsLoadRoomsError(true);
        } else {
          const toSort = rooms.data.allRooms.slice();
          setUserRooms(toSort.sort(sortByLastMessageCreated));
        }

        const orders = await apolloClient.query({
          query: allUserOrders,
          errorPolicy: 'all',
        });

        if (orders.errors || !orders.data) {
          setIsLoadOrdersError(true);
        } else {
          setUserOrders(orders.data.getUserOrders);
        }
      }
    }
    fetchUserData();
  }, [user, visibility]);

  useEffect(() => {
    if (userRooms.length > 0) {
      setRoomOptions([
        {
          label: 'ROOM NAME',
          options: userRooms.map((r) => ({
            value: r.id,
            label: r.title,
            customLabel: r.lastMessage.created,
          })),
        },
      ]);
    }

    if (userOrders.length > 0) {
      setOrderOptions([
        {
          label: 'ORDER #',
          options: userOrders.map((o) => ({
            value: o.orderNumber,
            label: formatCost(o.finalTotal),
            customLabel: o.created,
          })),
        },
      ]);
    }
  }, [userRooms, userOrders]);

  useEffect(() => {
    if (visibility && user) {
      analyticsTrack('contact-us-form-viewed', {
        user,
      });
    }
  }, []);

  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [contactReason, setContactReason] = useState('');
  const [details, setDetails] = useState('');
  const [showRoomInput, setShowRoomInput] = useState(false);
  const [roomName, setRoomName] = useState('');
  const [roomId, setRoomId] = useState('');
  const [orderNumber, setOrderNumber] = useState('');

  const [customerNameError, setCustomerNameError] = useState(false);
  const [customerEmailError, setCustomerEmailError] = useState(false);
  const [contactReasonError, setContactReasonError] = useState(false);
  const [detailsError, setDetailsError] = useState(false);
  const [roomNameError, setRoomNameError] = useState(false);
  const [roomIdError, setRoomIdError] = useState(false);
  const [orderNumberError, setOrderNumberError] = useState(false);

  const checkFormErrors = () => {
    const isInvalidEmail = !validateEmail(customerEmail);
    const checkableReason =
      contactReason && !otherReasons.includes(contactReason);

    setCustomerEmailError(!user && isInvalidEmail);
    setCustomerNameError(!user && checkableReason && !customerName);
    setRoomNameError(!user && checkableReason && !roomName);
    setContactReasonError(!contactReason);

    if (user && checkableReason) {
      if (showRoomInput) {
        setRoomIdError(!roomId);
      } else {
        setOrderNumberError(!orderNumber);
      }
    }
    setDetailsError(!details);

    return (
      (!user &&
        checkableReason &&
        (isInvalidEmail ||
          !customerName ||
          (showRoomInput ? !roomName : !orderNumber))) ||
      !contactReason ||
      (user && checkableReason && (showRoomInput ? !roomId : !orderNumber)) ||
      !details
    );
  };

  const contactReasonStyles = (): StylesConfig<
    Option,
    false,
    GroupBase<Option>
  > => {
    return {
      control: (currentStyles: any, { isFocused }) => ({
        ...currentStyles,
        height: 45,
        minHeight: 45,
        border: '1px solid #CCCCCC',
        ...(isFocused && {
          borderColor: '#5B7FB2',
          boxShadow: 'unset',
        }),
      }),
      valueContainer: (currentStyles: any) => ({
        ...currentStyles,
        padding: '0 0 0 6px',
        // height: 24
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      indicatorsContainer: (currentStyles: any) => ({
        ...currentStyles,
      }),
      option: (currentStyles: any, { isFocused, isSelected }) => {
        return {
          ...currentStyles,
          padding: '4px 0 4px 13px',
          marginLeft: 13,
          width: 321,
          ...(isFocused && {
            backgroundColor: '#E5EAF1',
          }),
          ...(isSelected && {
            backgroundColor: '#E5EAF1',
            color: 'inherit',
          }),
        };
      },
      placeholder: (currentStyles: any) => {
        return {
          ...currentStyles,
          color: '#999',
        };
      },
      groupHeading: (currentStyles: any) => {
        return {
          ...currentStyles,
          fontSize: 14,
          textTransform: 'unset',
          fontWeight: 400,
        };
      },
    };
  };

  const roomsOrdersSelectStyles = (): StylesConfig<
    Option,
    false,
    GroupBase<Option>
  > => {
    return {
      control: (currentStyles: any, { isFocused }) => ({
        ...currentStyles,
        height: 45,
        minHeight: 45,
        border: '1px solid #CCCCCC',
        ...(isFocused && {
          borderColor: '#5B7FB2',
          boxShadow: 'unset',
        }),
      }),
      valueContainer: (currentStyles: any) => ({
        ...currentStyles,
        padding: '0 0 0 6px',
        // height: 24
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      indicatorsContainer: (currentStyles: any) => ({
        ...currentStyles,
      }),
      groupHeading: (currentStyles: any) => {
        return {
          ...currentStyles,
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
        };
      },
      menuList: (currentStyles: any) => {
        return {
          ...currentStyles,
          paddingTop: 0,
        };
      },
      option: (currentStyles: any, { isFocused, isSelected }) => {
        return {
          ...currentStyles,
          padding: '4px 0 4px 12px',
          ...(isFocused && {
            backgroundColor: '#E5EAF1',
          }),
          ...(isSelected && {
            backgroundColor: '#E5EAF1',
            color: 'inherit',
          }),
        };
      },
      placeholder: (currentStyles: any) => {
        return {
          ...currentStyles,
          color: '#999',
        };
      },
    };
  };

  const [submitErr, setSubmitErr] = useState('');
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [doSubmit, setDoSubmit] = useState(false);
  const [contactUsPayload, setContactUsPayload] = useState<
    ContactUsPayload | undefined
  >();

  useEffect(() => {
    async function submitContactUsForm() {
      if (doSubmit) {
        const apolloClient = getApollo({
          jwt: user?.jwt,
          cacheEnabled: true,
        });

        const response = await apolloClient.mutate({
          mutation: sendContactUsMessageToCustomerExperienceTeam,
          variables: {
            payload: contactUsPayload,
          },
        });

        const res = response.data.sendContactUsMessageToCustomerExperienceTeam;

        if (!res.id || !res.type) {
          setSubmitErr('Failed to submit message.');
        } else {
          setIsSubmitSuccess(true);
        }
        setIsLoading(false);
      }
    }
    submitContactUsForm();
  }, [doSubmit]);

  const handleSubmit = async () => {
    setIsLoading(true);
    setContactUsPayload({
      brand: 'Havenly',
      email: user ? user.email! : customerEmail,
      name: user
        ? `${user.firstName}${user.lastName ? ` ${user.lastName}` : ''}`
        : customerName,
      userId: user && user.id,
      contactReason,
      ...(roomName && { roomName }),
      ...(roomId && { roomId }),
      ...(orderNumber && { orderNumber }),
      details,
    });

    if (!checkFormErrors()) {
      setDoSubmit(true);
    } else {
      setIsLoading(false);
    }
  };

  const clearState = () => {
    setCustomerName('');
    setCustomerNameError(false);

    setCustomerEmail('');
    setCustomerEmailError(false);

    setContactReason('');
    setContactReasonError(false);

    setDetails('');
    setDetailsError(false);

    setShowRoomInput(false);

    setRoomName('');
    setRoomNameError(false);

    setRoomId('');
    setRoomIdError(false);

    setOrderNumber('');
    setOrderNumberError(false);

    setIsLoading(false);
    setContactUsPayload(undefined);
    setDoSubmit(false);
    setSubmitErr('');
    setIsSubmitSuccess(false);
  };

  const handleModalClose = () => {
    clearState();
    onClose();
  };

  const isRoomRelatedReason = (opt: SingleValue<Option>) => {
    return contactReasonOptions[0].options
      .map((el) => el.value)
      .includes(opt!.value);
  };

  const handleReasonSelected = (opt: SingleValue<Option>) => {
    setContactReason(opt?.value as string);
    setContactReasonError(false);
    setShowRoomInput(isRoomRelatedReason(opt));
  };

  const handleRoomSelected = (opt: SingleValue<Option>) => {
    setRoomId(opt?.value as string);
    setRoomIdError(false);
  };

  const handleOrderSelected = (opt: SingleValue<Option>) => {
    setOrderNumber(opt?.value as string);
    setOrderNumberError(false);
  };

  const customRoomsGroupLabel = (data: GroupedOption) => (
    <div className={styles.RoomsGroupLabel}>
      <span className={styles.RoomName}>{data.label}</span>
      <span>LAST MESSAGE</span>
    </div>
  );

  const customRoomOptions = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    value,
    label,
    customLabel,
  }: {
    value: string;
    label: string;
    customLabel: string;
  }) => (
    <div className={styles.RoomsOptions}>
      <div className={styles.RoomName}>{label}</div>
      <div>
        {customLabel
          ? DateTime.fromISO(customLabel).toLocaleString({
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })
          : '--'}
      </div>
    </div>
  );

  const customOrdersGroupLabel = (data: GroupedOption) => (
    <div className={styles.OrdersGroupLabel}>
      <span className={styles.OrderNumber}>{data.label}</span>
      <span className={styles.OrderTotal}>TOTAL</span>
      <span>ORDER PLACED</span>
    </div>
  );

  const customOrderOptions = ({
    value,
    label,
    customLabel,
  }: {
    value: string;
    label: string;
    customLabel: string;
  }) => (
    <div className={styles.OrdersOptions}>
      <div className={styles.OrderNumber}>{value}</div>
      <div className={styles.OrderTotal}>{label}</div>
      <div>
        {customLabel
          ? DateTime.fromISO(customLabel).toLocaleString({
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })
          : '--'}
      </div>
    </div>
  );

  const getRoomInput = (reason: string) => {
    return user && userRooms.length > 0 && !isLoadRoomsError ? (
      <div className={styles.Field}>
        <label htmlFor="customer-room">
          Room Name
          {!otherReasons.includes(reason) && (
            <span className={styles.Asterisk}>*</span>
          )}
        </label>
        <GroupedOptionsSelect
          className={styles.Select}
          selectStyles={roomsOrdersSelectStyles()}
          groupStyles={styles.Group}
          formatOptionLabel={customRoomOptions}
          formatGroupLabel={customRoomsGroupLabel}
          onSelected={(opt) => handleRoomSelected(opt)}
          options={roomOptions}
          placeholder="Your room name"
          selectedValueProp="label"
        />
        {roomIdError && <p className={styles.Error}>Please select a room</p>}
      </div>
    ) : (
      <div className={styles.Field}>
        <label htmlFor="customer-room">
          Room Name
          {!otherReasons.includes(reason) && (
            <span className={styles.Asterisk}>*</span>
          )}
        </label>
        <input
          id="customer-room"
          name="customer-room"
          type="text"
          onChange={(e) => {
            setRoomName(e.target.value);
            setRoomNameError(false);
          }}
          placeholder="Your room name"
        />
        {roomNameError && (
          <p className={styles.Error}>Please enter your room name</p>
        )}
      </div>
    );
  };

  const getOrderInput = (reason: string) => {
    return user && userOrders.length > 0 && !isLoadOrdersError ? (
      <div className={styles.Field}>
        <label htmlFor="customer-room">
          Order Number
          {!otherReasons.includes(reason) && (
            <span className={styles.Asterisk}>*</span>
          )}
        </label>
        <GroupedOptionsSelect
          className={styles.Select}
          selectStyles={roomsOrdersSelectStyles()}
          groupStyles={styles.Group}
          formatOptionLabel={customOrderOptions}
          formatGroupLabel={customOrdersGroupLabel}
          onSelected={(opt) => handleOrderSelected(opt)}
          options={orderOptions}
          placeholder="Your order number"
          selectedValueProp="value"
        />
        {orderNumberError && (
          <p className={styles.Error}>Please enter an order number</p>
        )}
      </div>
    ) : (
      <div className={styles.Field}>
        <label htmlFor="customer-room">
          Order Number
          {!otherReasons.includes(reason) && (
            <span className={styles.Asterisk}>*</span>
          )}
        </label>
        <input
          id="customer-room"
          name="customer-room"
          type="text"
          onChange={(e) => {
            setOrderNumber(e.target.value);
            setOrderNumberError(false);
          }}
          placeholder="Your order number"
        />
        {orderNumberError && (
          <p className={styles.Error}>Please enter an order number</p>
        )}
      </div>
    );
  };

  return (
    <Modal
      animation
      dialogClassName={classNames(styles.ModalDialog, {
        [styles.isLoggedIn]: user,
      })}
      onHide={handleModalClose}
      show={visibility}
    >
      <div className={styles.Top}>
        <button
          type="button"
          onClick={handleModalClose}
          className={styles.CloseButton}
        >
          <CloseIcon />
        </button>
      </div>
      <div className={styles.Content}>
        <div className={styles.MobileTop}>
          <button
            type="button"
            onClick={handleModalClose}
            className={styles.CloseButton}
          >
            <CloseIcon />
          </button>
        </div>
        {isSubmitSuccess ? (
          <>
            <div className={styles.SuccessHeader}>
              <div className={styles.Thanks}>SUCCESS</div>
              <div className={styles.H1}>{`Thanks for reaching out!`}</div>
              <div className={styles.H2}>
                {`We received your message and our customer team will reach out to you resolve the issue!`}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.Header}>
              <div className={styles.ContactUs}>CONTACT US</div>
              <div className={styles.H1}>{`We're Here To Help`}</div>
              <div className={styles.H2}>
                {`Please fill out the form below so we can better assist you! \nWe look forward to hearing from you.`}
              </div>
            </div>
            <div className={styles.FormWrapper}>
              <form
                className={styles.Form}
                style={{ background: theme.colors.core2 }}
                onSubmit={handleSubmit}
              >
                <div className={styles.FieldGroup}>
                  {submitErr && (
                    <p className={styles.Error}>
                      <span>{submitErr}</span>
                    </p>
                  )}

                  {!user && (
                    <>
                      <div className={styles.Field}>
                        <label htmlFor="customer-name">
                          First and Last Name
                          <span className={styles.Asterisk}>*</span>
                        </label>
                        <input
                          id="customer-name"
                          name="customer-name"
                          type="text"
                          onChange={(e) => {
                            setCustomerName(e.target.value);
                            setCustomerNameError(false);
                          }}
                          placeholder="First and last name"
                        />
                        {customerNameError && (
                          <p className={styles.Error}>Please enter your name</p>
                        )}
                      </div>
                      <div className={styles.Field}>
                        <label htmlFor="cutomer-email">
                          Email<span className={styles.Asterisk}>*</span>
                        </label>
                        <input
                          id="cutomer-email"
                          name="cutomer-email"
                          type="text"
                          onChange={(e) => {
                            setCustomerEmail(e.target.value);
                            setCustomerEmailError(false);
                          }}
                          placeholder="Email associated with your Havenly account"
                        />
                        {customerEmailError && (
                          <p className={styles.Error}>
                            Please enter a valid email
                          </p>
                        )}
                      </div>
                    </>
                  )}
                  <div className={styles.Field}>
                    <label>
                      Contact Reason<span className={styles.Asterisk}>*</span>
                    </label>
                    <GroupedOptionsSelect
                      options={contactReasonOptions}
                      selectStyles={contactReasonStyles()}
                      onSelected={(opt) => handleReasonSelected(opt)}
                      className={styles.Select}
                    />
                    {contactReasonError && (
                      <p className={styles.Error}>
                        Please select a reason for contact
                      </p>
                    )}
                  </div>

                  {contactReason &&
                    contactReason !== 'Something Else' &&
                    (showRoomInput
                      ? getRoomInput(contactReason)
                      : getOrderInput(contactReason))}

                  <div className={styles.Field}>
                    <label htmlFor="detail">
                      Details<span className={styles.Asterisk}>*</span>
                    </label>
                    <textarea
                      id="detail"
                      name="detail"
                      onChange={(e) => {
                        setDetails(e.target.value);
                        setDetailsError(false);
                      }}
                      placeholder={`What can we help with?`}
                    />
                    {detailsError && (
                      <p style={{ marginTop: 4 }} className={styles.Error}>
                        Please enter details
                      </p>
                    )}
                  </div>
                </div>
              </form>
              <div className={styles.ButtonWrapper}>
                <button
                  type="button"
                  onClick={handleSubmit}
                  className={classNames(
                    theme.styles.Button,
                    theme.styles.Primary,
                    { [theme.styles.Disabled]: isLoading }
                  )}
                >
                  Submit
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ContactUsModal;
