import React from 'react';
import If from '@components/shared/conditional/If/If';
import SearchIcon from './search.svg';
import CloseIcon from './close.svg';
import styles from './SearchBar.module.scss';

interface ISearchBarProps {
  autoFocus?: boolean;
  className?: string;
  value?: string;
  onClose?: () => void;
  onChange?: (value: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  searchIconFirst?: boolean;
  showCloseIcon?: boolean;
}

const SearchBar = ({
  autoFocus = false,
  className,
  value,
  onClose,
  onChange,
  onSubmit,
  searchIconFirst = false,
  showCloseIcon = false,
}: ISearchBarProps) => (
  <form
    className={`${styles.SearchBox} ${className || ''}`}
    onSubmit={onSubmit}
  >
    <If condition={searchIconFirst}>
      <button type="submit">
        <SearchIcon />
      </button>
    </If>
    <input
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={ autoFocus }
      type="text"
      placeholder="Search"
      value={value}
      onChange={
        (e) => {
          if (onChange) {
            onChange(e.target.value, e);
          }
        }
      }
    />
    <If condition={!searchIconFirst}>
      <button type="submit">
        <SearchIcon />
      </button>
    </If>
    <If condition={showCloseIcon}>
      <button type="button" onClick={onClose}>
        <CloseIcon />
      </button>
    </If>
  </form>
);

export default SearchBar;
