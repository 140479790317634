import React, { ComponentType, ReactNode } from 'react';
import Select, {
  components,
  FormatOptionLabelMeta,
  GroupBase,
  GroupProps,
  SingleValueProps,
} from 'react-select-latest';
import { ISelect, Option } from './model';

const GroupedOptionsSelect = ({
  options,
  selectStyles,
  groupStyles,
  onSelected,
  className,
  placeholder,
  formatOptionLabel,
  formatGroupLabel,
  selectedValueProp,
  isOpen,
}: ISelect) => {
  const Group = (props: GroupProps<Option, false>) => (
    <div>
      <components.Group className={groupStyles} {...props} />
    </div>
  );

  const SingleValue = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    children,
    ...props
  }: SingleValueProps<Option>) => (
    <components.SingleValue {...props}>
      {selectedValueProp === 'value' ? props.data.value : props.data.label}
    </components.SingleValue>
  );

  return (
    <Select<Option>
      key={`grouped_options_select_${options[0].label}`}
      options={options}
      className={className}
      components={{
        Group,
        SingleValue: SingleValue as ComponentType<
          SingleValueProps<Option, false, GroupBase<Option>>
        >,
      }}
      formatOptionLabel={
        formatOptionLabel as unknown as (
          data: Option,
          formatOptionLabelMeta: FormatOptionLabelMeta<Option>
        ) => ReactNode
      }
      formatGroupLabel={
        formatGroupLabel as unknown as (group: GroupBase<Option>) => ReactNode
      }
      placeholder={placeholder || 'Please select one'}
      onChange={(opt) => onSelected(opt)}
      styles={selectStyles}
      isSearchable={false}
      menuIsOpen={isOpen}
    />
  );
};

export default GroupedOptionsSelect;
