import gql from 'graphql-tag';

import { ICartProduct } from '../fragments/cart-product.graphql';

export type { ICartProduct } from '../fragments/cart-product.graphql';

export interface IMergeLocalCartIntoUserCartData {
  mergeLocalCartIntoUserCart: ICartProduct[];
}

export default gql`
  mutation MergeLocalCartIntoUserCart(
    $cartProducts: [CreateUpdateCartProductInput!]
    $savedForLaterProducts: [CreateUpdateCartProductInput!]
  ) {
    mergeLocalCartIntoUserCart(
      cartProducts: $cartProducts
      savedForLaterProducts: $savedForLaterProducts
    ) {
      id
    }
  }
`;
