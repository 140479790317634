import gql from 'graphql-tag';

export interface ICart {
  id: string;
  cartProducts: ICartProduct[];
}

export interface ICartProduct {
  id: string;
  cart?: ICart;
  quantity: number;
  vendorVariantId: string;
  vendorVariant: {
    id: string;
    sku: string;
    productCanonicalUrl: string;
  };
}

export default gql`
  fragment CartProductFragment on CartProductModel {
    id
    quantity
    vendorVariantId
    vendorVariant {
      availability
      id
      currentPrice
      discountedPrice
      images {
        id
        uri
      }
      productCanonicalUrl
      retailPrice {
        id
        price
      }
      sku
      taxonomy {
        id
        title
      }
      title
      vendor {
        id
        name
      }
    }
  }
`;
