import React from 'react';
import styles from './Button.module.scss';

export default function Button(props) {
  const { className, desktopOnly } = props;
  return (
    <a
      rel="nofollow"
      className={ `
        ${styles.Button}
        ${className}
        ${desktopOnly ? styles.isDesktopOnly : ''}
      ` }
      href="/style-quiz"
      onClick={ () => { props.onClickNavItem('Get Started'); } }
    >
      Get Started
    </a>
  );
}
