import React from 'react';
import SearchIcon from '../../images/search.svg';
import styles from './Search.module.scss';

export default function Search(props) {
  const { className, isSearchOpen } = props;
  return (
    <button
      aria-label={isSearchOpen ? 'close search bar icon' : 'search icon'}
      className={ `${styles.Search} ${className}` }
      onClick={ () => { props.onClickNavItem('Search'); } }
      type="button"
    >
      {isSearchOpen ? (
        <div
          className={ `${styles.XContainer}` }
        >
          <div className={ styles.Line } />
          <div className={ styles.Line } />
        </div>
      ) : (
        <SearchIcon className={ styles.SearchIcon } />
      )}
    </button>
  );
}
