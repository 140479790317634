import { startCase } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const hashCode = (str?: string) => {
  let h = 0;
  if (str) {
    for (let i = 0; i < str.length; i += 1) {
      // eslint-disable-next-line no-bitwise
      h = Math.imul(31, h) + str.charCodeAt(i) | 0;
    }
  }

  return h;
};

export const substringAfter = (str: string, delimiter: string) => {
  if (delimiter) {
    const delimiterIndex = str.indexOf(delimiter);
    if (delimiterIndex >= 0) {
      return str.slice(delimiterIndex + 1);
    } else {
      return '';
    }
  } else {
    return str;
  }
};

export const substringBefore = (str: string, delimiter: string) => {
  if (delimiter) {
    const delimiterIndex = str.indexOf(delimiter);
    if (delimiterIndex >= 0) {
      return str.slice(0, delimiterIndex);
    } else {
      return '';
    }
  } else {
    return str;
  }
};

export const substringAfterLast = (str: string, delimiter: string) => {
  if (delimiter) {
    const delimiterIndex = str.lastIndexOf(delimiter);
    if (delimiterIndex >= 0) {
      return str.slice(delimiterIndex + 1);
    } else {
      return '';
    }
  } else {
    return str;
  }
};

export const substringBeforeLast = (str: string, delimiter: string) => {
  if (delimiter) {
    const delimiterIndex = str.lastIndexOf(delimiter);
    if (delimiterIndex >= 0) {
      return str.slice(0, delimiterIndex);
    } else {
      return '';
    }
  } else {
    return str;
  }
};

export function beautifyCityString(str: string): string {
  let city = startCase(str);

  if (city.toLowerCase() === 'washington dc') {
    city = 'Washington DC';
  }

  return city;
}
