export default {
  isIosNativeApp(userAgent: string) {
    return /Havenly\/iOS/i.test(userAgent);
  },
  /**
   * stolen from here: https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
   * returns true if device is iOS device
   * caution: only works browser side, do not use in SSR environments
   * not to be trusted, navigator.platform is deprecated according to MDN
   * https://developer.mozilla.org/en-US/docs/Web/API/Navigator#deprecated_properties
   */
  isIOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform)
    // iPad on iOS 13+ detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    || /\biPhone\b|\biPod\b/.test(navigator.userAgent);
  },
};
