import gql from 'graphql-tag';

export const allRooms = gql`
query AllRooms($filters: RoomQueryFilterInput) {
  allRooms(filters: $filters) {
    id
    designFee
    created
    modified
    title
    lastMessage {
      created
    }
  }
}`;

export default allRooms;
