import React from 'react';
import styles from './Nav.module.scss';
import headerStyles from '../Header.module.scss';
import Chevron from '../../images/chevron.svg';
import NavLink from './NavLink';

const NavDropDownMenu = (props) => {
  const { link } = props;

  return (
    <div
      className={`
                ${styles.Link}
                ${styles.DropdownHeader}
                ${link.mobileOnly ? headerStyles.MobileOnly : ''}
              `}
      key={link.title}
    >
      <LinkDropdownTitle url={link.url}>
        {link.title}
        <span className={styles.Chevron}>
          <Chevron />
        </span>
      </LinkDropdownTitle>

      <div className={styles.SubNav}>
        {
          link.links.map((subLink) => (
            <NavLink key={subLink.title} {...props} link={subLink} parentTitle={link.title} />
          ))
        }
      </div>
    </div>
  );
};

export default NavDropDownMenu;

function LinkDropdownTitle({ url, children }) {
  if (url) {
    return (
      <a
        className={ styles.LinkDropdownTitle }
        href={ url }
      >
        {children}
      </a>
    );
  }

  return <div className={ styles.LinkDropdownTitle }>{children}</div>;
}
