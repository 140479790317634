import {
  ISubPageItem,
  ISubPageItemLeaf,
} from '@components/shared/Navigation/models/sub-page';
import { useTheme } from '@components/theme';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import Divider from '@components/shared/Divider/Divider';
import { useRouter } from 'next/router';
import styles from './SubPage.module.scss';
import ChevronLeftIcon from './assets/Chevron-Left.svg';

export interface ISubPageProps {
  title: string;
  url?: string;
  subNavigationMenu?: Array<ISubPageItem | ISubPageItemLeaf>;
  handleGoBack: () => void;
}

export default function SubPage({
  title,
  url,
  subNavigationMenu: rootMenuItems,
  handleGoBack
}: ISubPageProps) {
  const router: NextRouter = useRouter();

  const theme = useTheme();

  return (
    <div className={classNames(theme.styles.Container)}>
      <div className={styles.ListWrapper}>
        <button
          type="button"
          className={classNames(
            theme.styles.Title2,
            theme.styles.Core4,
            styles.Header
          )}
          onClick={handleGoBack}
        >
          <ChevronLeftIcon />
          <span>Back</span>
        </button>

        <div className={styles.CategoryChildrenList}>
          <div className={styles.ShopHome}>
            <a href={url}>{title}</a>
          </div>
          <Divider />
          {rootMenuItems?.map(({
            title: itemTitle,
            url: itemUrl,
          }) => (
            <Fragment key={itemUrl}>
              <a
                key={url}
                href={itemUrl}
                className={classNames(
                  styles.MerchGroupCategoryAccordion,
                  { [styles.isSelected]: router.asPath === itemUrl }
                )}
              >
                {itemTitle}
              </a>
              <Divider />
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}
