import gql from 'graphql-tag';

export interface Order {
  id: GraphQLID;
  orderNumber: string;
  finalTotal: number;
  created: string;
}

export const allUserOrders = gql`
query getUserOrders {
  getUserOrders {
    id
    orderNumber
    finalTotal
    created
  }
}`;

export default allUserOrders;
