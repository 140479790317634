import React from 'react';
import { useTheme } from '@components/theme';

import handleNavigationClick from '@utils/navigation/handleNavigationClick';
import Image from '@components/shared/Image/Image';
import styles from './ImageMegaMenu.module.scss';

export interface IImageMegaMenuLink {
  title: string;
  imageSrc?: string;
  url?: string;
  text?: string;
  links?: Array<{title: string; url: string}>;
}

export interface IImageMegaMenuProps {
  megaMenu: IImageMegaMenuLink[];
}

const ImageMegaMenu = ({
  megaMenu
}: IImageMegaMenuProps) => {
  const theme = useTheme();
  return (
    <>
      {megaMenu
        .filter(({ imageSrc, url }) => (imageSrc && url))
        .map(({ imageSrc, title, url }) => {
          return (
            <a
              key={title}
              className={styles.ImageContainer}
              href={url!}
              onClick={handleNavigationClick(title)}
            >
              <Image
                src={imageSrc!}
                alt={title}
                layout="responsive"
                width={650}
                height={422}
                sizes="23vw"
              />
              <p className={theme.styles.Title2}>{title}</p>
            </a>
          );
        })}
    </>
  );
};

export default ImageMegaMenu;
