import React from 'react';
import styles from './Account.module.scss';

const getUserInitials = (user) => {
  const name = user.name ?? user.firstName;

  const nameArray = name
    .trim()
    .replace(/\s\s+/g, ' ')
    .split(/\b(\s)/);
  if (nameArray.length === 1) {
    return nameArray[0][0];
  }

  return nameArray[0][0] + nameArray[nameArray.length - 1][0];
};

export default function Account(props) {
  const {
    className,
    user,
    links,
    isScrolled,
    isTransparent,
    isConflictingStyle
  } = props;

  return (
    <div
      className={ `
        ${styles.Account}
        ${className}
        ${isScrolled ? styles.isScrolled : ''}
        ${isTransparent ? styles.isTransparent : ''}
        ${isConflictingStyle ? styles.isConflicting : ''}
      ` }
    >
      <a
        className={ styles.Initials }
        href="/users/account"
        onClick={ () => { props.onClickNavItem('Account'); } }
      >
        {getUserInitials(user)}
      </a>

      <nav className={ styles.Dropdown }>
        {links.map((link) => (
          <React.Fragment key={ link.title }>
            {link.separator ? (
              <div className={ styles.Separator } />
            ) : null}
            <a
              className={ styles.Link }
              href={ link.url }
              onClick={ () => { props.onClickNavItem(link.title, 'Account'); } }
            >
              {link.title}
            </a>
          </React.Fragment>
        ))}
      </nav>
    </div>
  );
}
