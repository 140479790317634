import React from 'react';
import styles from './MegaMenuMobile.module.scss';
import Chevron from '../../images/chevron.svg';
import CurveShape from '../../images/curve-shape.svg';
import Stars from '../../images/stars.svg';
import headerStyles from '../Header.module.scss';

interface IMegaMenu {
  closeMegaMenu:() => void;
  links:{
    imageTitle: string;
    imageSrc: string;
    imageText: string;
    textFooter: string;
    imageLink: any;
    links:any;
    mobileOnly:string;
    title:string;
    url:string;
  }[];
  currentUrl:string;
}

const renderLink = (link:any) => {
  const classes = `
    ${styles.LinkDetails}
  `;
  return (
    <a
      className={ classes }
      href={ link.url }
      key={ link.title }
    >
      {link.title}
    </a>
  );
};

const MegaMenuMobile = ({
  closeMegaMenu,
  links
}:IMegaMenu) => {
  return (
    <nav
      className={ `
                ${styles.MegaMenu}
                ${styles.isOpen}
              `}
    >
      <div className={ styles.wrapperNav }>
        <button
          type={'button'}
          key={'megamenu'}
          onClick={closeMegaMenu}
          className={ styles.ChevronLeft }
        >
          <Chevron />
          <span>Back</span>
        </button>
        <div className={styles.wrapperLinks}>
          {links.map((link:any) => {
            if (link.links) {
              return (
                <div key={link.title}>
                  <div
                    className={ `
                      ${styles.Header}
                      ${link.mobileOnly ? headerStyles.MobileOnly : ''}
                    ` }
                    key={ link.title }
                  >
                    <a
                      href={ link.url }
                    >
                      {link.title}
                    </a>
                  </div>
                  <div className={styles.SectionLinks}>
                    {
                      link.links.map((subLink:any) => (
                        renderLink(subLink)
                      ))
                    }
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
        <a className={ styles.imageWrapper } href={ links[3].imageLink }>
          <img alt={links[3].imageTitle} className={ styles.ImageFooter } src={links[3].imageSrc} />

          <span className={ styles.ImageTextWrapper }>
            <p className={ styles.ImageTextTitle }>
              { links[3].imageTitle }
            </p>
            <p className={ styles.ImageTextContent }>
              { links[3].imageText }
            </p>
          </span>
        </a>
      </div>
      <div className={styles.wrapperFooter}>
        <CurveShape />
        <p>
          { links[3].textFooter }
          <Stars />
        </p>
      </div>
    </nav>
  );
};

export default MegaMenuMobile;
