import Button from '../Button/Button';
import LoginSignup from '../LoginSignup/LoginSignup';
import Logo from '../Logo/Logo';
import Nav from '../Nav/Nav';
import Search from '../Search/Search';
import megaMenuInsplore from './megaMenuInsplore.config';
import { IConfigOptions } from './i-config';

import logoStyles from '../Logo/Logo.module.scss';

export default (opts: IConfigOptions) => {
  return [{
    component: Nav,
    key: 'NavLinks',
    props: {
      mobileHelpLink: true,
      mobileLoginSignup: true,
      links: [
        {
          title: 'How It Works',
          links: [
            {
              title: 'The Details',
              url: '/interior-design-services',
            },
            {
              title: 'Designers',
              url: '/interior-designers',
            },
            {
              title: 'Pricing',
              url: '/pricing',
            },
            {
              title: 'Reviews',
              url: '/reviews',
            },
            {
              title: 'Style Quiz',
              url: '/interior-design-style-quiz',
            },
          ],
        },
        {
          title: 'Blog',
          url: '/blog/',
        },
        {
          title: 'Portfolio',
          url: '/exp/interior-design-ideas',
          megaMenu: megaMenuInsplore(opts),
        },
      ],
    },
  },
  {
    component: Logo,
    key: 'Logo',
    props: {
      className: logoStyles.LoggedOutLogo,
    },
  },
  {
    component: LoginSignup,
    key: 'LoginSignup',
    props: {
      desktopOnly: true,
    },
  },
  {
    component: Search,
    key: 'Search',
    props: {},
  },
  {
    component: Button,
    key: 'Button',
    props: {
      desktopOnly: true,
    },
  }];
};
