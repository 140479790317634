import React from 'react';
import HavenlyLogo from '../../images/havenly-logo.svg';
import styles from './Logo.module.scss';

export default function Logo(props) {
  const { className, onClickNavItem, href = '/' } = props;
  return (
    <a
      aria-label="Havenly Logo"
      className={ `${styles.Logo} ${className}` }
      href={ href }
      onClick={ () => { onClickNavItem('Logo'); } }
    >
      <span>
        <HavenlyLogo />
      </span>
    </a>
  );
}
