import gql from 'graphql-tag';
import { ApolloQueryResult } from '@apollo/client';

import CartProductFragment from '../fragments/cart-product.graphql';

export default gql`
  query cartForUser {
    cartForUser {
      id
      cartProducts {
        ...CartProductFragment
      }
    }
  }
  ${CartProductFragment}
`;

export const getUserCartVendorVariants = (queryResponse: ApolloQueryResult<any>) => {
  if (!queryResponse?.data?.cartForUser?.cartProducts?.length) {
    return {};
  }

  return queryResponse.data.cartForUser.cartProducts
    .reduce((acc: any, post: any) => {
      const { vendorVariantId, quantity } = post;

      return { ...acc, [vendorVariantId]: quantity };
    }, {});
};
