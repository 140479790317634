import React from 'react';
import classNames from 'classnames';
import { useTheme } from '@components/theme';
import handleNavigationClick from '@utils/navigation/handleNavigationClick';
import Image from '@components/shared/Image/Image';
import styles from './MegaMenu.module.scss';

interface IMegaMenuDesktop {
  menuColumns: IMegaMenuColumn[];
}

interface ILink {
  title: string;
  url: string;
}

export interface IMegaMenuColumn {
  image?: {
    src: string;
    link: string;
  };
  imageTitle?: string; // deprecated
  imageSrc?: string; // deprecated
  imageText?: string; // deprecated
  textFooter?: string; // deprecated
  imageLink?: any; // deprecated
  links?: ILink[];
  title?: string;
  text?: string;
  url?: string;
  twoCols?: boolean;
}

const MegaMenuDesktop = ({
  menuColumns
}: IMegaMenuDesktop) => {
  const theme = useTheme();

  const subMenus = menuColumns.filter(({ links }) => links);
  const images = menuColumns.filter(({ image }) => image);

  // todo: limit number of images for certain viewports

  const imageWidth = images.length === 1 ? 420 : 260;
  const imageHeight = images.length === 2 ? 420 : 275;

  return (
    <div className={classNames(styles.MegaMenu)}>
      {
        subMenus.map(({ title, links, twoCols }: IMegaMenuColumn) => (
          <div
            key={title}
            className={classNames(
              styles.SubMenu,
              { [styles.TwoCols]: twoCols }
            )}
          >
            <span className={classNames(
              theme.styles.Title3,
              styles.SubMenuTitle
            )}
            >
              {title}
            </span>
            <div className={styles.SubMenuLinks}>
              {links!.map((link) => (
                <SubMenuLink {...link} key={link.title} />
              ))}
            </div>
          </div>
        ))
      }
      <div className={classNames(styles.ImageGroup)}>
        {
          images.map(({ title, text, image }: IMegaMenuColumn) => {
            const { src, link } = image!;

            return (
              <a key={title} className={styles.Image} href={link}>
                <Image
                  src={src}
                  alt={title}
                  height={imageHeight}
                  width={imageWidth}
                  layout="fixed"
                  placeholder="blur"
                />
                {(title || text) && (
                  <span className={styles.ImageText}>
                    {title && (
                      <h3 className={classNames({
                        [theme.styles.Title3]: images.length < 2,
                        [theme.styles.Title4]: images.length >= 2,
                      })}
                      >{title}
                      </h3>
                    )}
                    {text && <p>{text}</p>}
                  </span>
                )}
              </a>
            );
          })
        }
      </div>
    </div>
  );
};

export default MegaMenuDesktop;

function SubMenuLink({ title, url }: ILink) {
  return (
    <a
      className={classNames(styles.LinkDetails)}
      href={url}
      onClick={handleNavigationClick(title)}
      key={title}
    >
      {title}
    </a>
  );
}

