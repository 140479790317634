import React, { useState } from 'react';
import classNames from 'classnames';
import Divider from '@components/shared/Divider/Divider';
import PurePlusIcon from '@components/shared/images/atoms/PurePlusIcon/PurePlusIcon';
import PureMinusIcon from '@components/shared/images/atoms/PureMinusIcon/PureMinusIcon';
import styles from './AccordionItem.module.scss';

interface IAccordionProps {
  className?: string;
  titleClassName?: string;
  dividerClassName?: string;
  iconClassName?: string;
  itemClassName? :string;
  headerButtonClass?: string;
  title: string;
  showIcon?: boolean;
  showDivider?: boolean;
  openedInitialState?: boolean;
  headerButtonIsOpenClassName?: string;
}

const AccordionItem: React.FC<IAccordionProps> = ({
  children,
  title,
  className = '',
  showIcon = false,
  showDivider = false,
  openedInitialState = false,
  dividerClassName,
  iconClassName,
  titleClassName,
  itemClassName,
  headerButtonClass,
  headerButtonIsOpenClassName,
}) => {
  const [isOpen, setIsOpen] = useState(openedInitialState);

  return (
    <div className={classNames(className)}>
      <button
        type="button"
        className={classNames(
          styles.HeaderButton,
          headerButtonClass,
          isOpen && headerButtonIsOpenClassName
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={classNames(
          styles.Title,
          titleClassName
        )}
        >
          {title}
        </span>
        {showIcon && (
          <div className={classNames(
            styles.Icon,
            iconClassName
          )}
          >
            {isOpen ? (
              <PureMinusIcon />
            ) : (
              <PurePlusIcon />
            )}
          </div>
        )}
      </button>

      <div className={classNames(
        styles.AccordionBody,
        { [styles.IsOpen]: isOpen },
        itemClassName
      )}
      >
        {children}
      </div>
      {showDivider && (
        <Divider className={dividerClassName} />
      )}
    </div>
  );
};

export default AccordionItem;
