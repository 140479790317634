import React, { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import UserContext from '@context/User/user-context';
import useMergeLocalCartIntoUserCart from '@hooks/User/useMergeLocalCartIntoUserCart';
import { LocalCartContext } from '@context/LocalCart/LocalCartContext';
import ShopIcon from '../../images/shop-icon.svg';
import styles from './Cart.module.scss';

const cartCounterStyles = ({ cartItemCount }) => `
  header-nav-cart-counter
  ${styles.CartCounter}
  ${Number(cartItemCount) > 0 ? styles.HasItems : ''}
`;

export default function Cart(props) {
  const router = useRouter();
  const { user } = useContext(UserContext);
  const userId = (user && Number(user.id)) || undefined;
  const { totalCartItems } = useContext(LocalCartContext);

  const [mergeCart] = useMergeLocalCartIntoUserCart();
  useEffect(() => {
    if (
      totalCartItems > 0 &&
      !props.skipMergeCart &&
      userId &&
      router?.query.register !== undefined
    ) {
      router.replace(router.pathname, null, { shallow: true });
      mergeCart();
    }
  }, [userId, totalCartItems]);

  const {
    cartItemCount,
    className,
    isScrolled,
    isTransparent,
    isNavOpen,
    isConflictingStyle
  } = props;
  return (
    <a
      className={`
        ${styles.Cart}
        ${className}
        ${isScrolled ? styles.isScrolled : ''}
        ${isTransparent ? styles.isTransparent : ''}
        ${isNavOpen ? styles.isNavOpen : ''}
        ${isConflictingStyle ? styles.isConflicting : ''}
      `}
      href="/cart"
      onClick={() => {
        props.onClickNavItem('Cart');
      }}
    >
      <div className={cartCounterStyles(props)}>{cartItemCount}</div>
      <span>
        <ShopIcon />
      </span>
    </a>
  );
}
