import gql from 'graphql-tag';

export default gql`
  mutation SendContactUsMessageToCustomerExperienceTeam($payload: ContactUsModelInput!) {
    sendContactUsMessageToCustomerExperienceTeam(payload: $payload){
      id
      type
    }
  }
`;
