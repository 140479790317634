import React from 'react';
import styles from './Nav.module.scss';
import headerStyles from '../Header.module.scss';

const isLinkActive = (link, currentUrl) => {
  if (!currentUrl) {
    return false;
  }

  // The current page
  if (link.exactActive ? currentUrl === link.url : currentUrl.startsWith(link.url)) {
    return true;
  }

  // Recursively check sub-links
  if (link.links && link.links.some((subLink) => isLinkActive(subLink, currentUrl))) {
    return true;
  }

  // Child page regexes that aren't in the sub-nav
  if (link.children && link.children.some((child) => currentUrl.search(child) === 0)) {
    return true;
  }

  return false;
};

const NavLink = (props) => {
  const {
    link,
    currentUrl,
    parentTitle,
    onClickNavItem,
  } = props;

  const isActive = isLinkActive(link, currentUrl);

  const classes = `
    ${parentTitle ? styles.SubLink : styles.Link}
    ${link.mobileOnly ? headerStyles.MobileOnly : ''}
    ${isActive ? styles.active : ''}
  `;

  return (
    <a
      className={ classes }
      href={ link.url }
      key={ link.title }
      onClick={ () => { onClickNavItem(link.title, (parentTitle ? `${parentTitle} > ` : '')); } }
    >
      {link.title}
    </a>
  );
};

export default NavLink;
