import React from 'react';
import Logo from './logo.svg';

const HavenlyLogo = ({ className }: { className: string }) => (
  <div className={className}>
    <Logo />
  </div>
);

export default HavenlyLogo;
