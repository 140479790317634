import { useTheme } from '@components/theme';
import classNames from 'classnames';
import React from 'react';
import styles from './Copyright.module.scss';

const Copyright = () => {
  const theme = useTheme();
  const links = [
    {
      text: 'Find us in the App Store',
      url: 'https://itunes.apple.com/us/app/havenly/id1149153371',
    },
    {
      text: 'Privacy Policy',
      url: '/privacy',
    },
    {
      text: 'Terms of Service',
      url: '/terms',
    },
    {
      text: 'ADA Accessibility',
      url: '/ada-accessibility',
    },
    {
      text: 'Do Not Sell My Personal Information',
      url: '/datasettings'
    }
  ];

  const currentYear = new Date().getFullYear();

  return (
    <div className={classNames(theme.styles.Caption, styles.Copy)}>
      <p className={styles.Copyright}>{`${currentYear} Havenly Inc., All Rights Reserved.`}</p>
      <div className={styles.Links}>
        {
          links.map((link) => (
            <span key={link.text}>
              <a href={link.url}>
                {link.text}
              </a>
              <span>&nbsp;|&nbsp;</span>
            </span>
          ))
        }
      </div>
    </div>
  );
};

export default Copyright;
