import Cookie from 'js-cookie';

import { HAVENLY_ANONYMOUS_ID_COOKIE } from '@lib/cookie/havenlyAnonymousId';
import env from '../envs/env';
import { convertProps } from './converters';

/**
 * Records whenever a user sees a page of your website,
 * along with any optional properties about the page.
 *
 * @param name The name of the page to record.
 * @param properties Free-form dictionary of properties for this page.
 */
const analyticsPage = (
  name?: string,
  properties?: {[key: string]: any},
) => {
  if (!env.ANALYTICS_ENABLED || !window?.analytics?.page) {
    return;
  }

  const convertedProps = convertProps(properties) || {};
  convertedProps.havenly_anonymous_id = Cookie.get(HAVENLY_ANONYMOUS_ID_COOKIE);
  window.analytics.page(name, convertedProps);
};
export default analyticsPage;
