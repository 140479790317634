import classNames from 'classnames';
import React from 'react';
import styles from './Divider.module.scss';

interface IDividerProps {
  className?: string;
}

const Divider = ({
  className
}: IDividerProps) => (
  <div
    className={classNames(
      styles.Divider,
      className
    )}
  />
);

export default Divider;
