import React from 'react';
import { sortBy } from 'lodash';
import { beautifyCityString } from '@lib/string';
import { IMegaMenuColumn } from '@components/shared/Navigation/components/MainHeader/Desktop/MegaMenu/MegaMenu';
import { ILink } from '@components/shared/Navigation/components/MainHeader/components/NavLinks/links';
import SubPage, { ISubPageProps } from '../MainHeader/components/SubPages/SubPage/SubPage';
import locationConfig from './location.config';

const locationNavCities = [
  'austin',
  'chicago',
  'dallas',
  'denver',
  'houston',
  'new-york',
  'boston',
  'charlotte',
  'los-angeles',
  'philadelphia',
  'seattle',
  'washington-dc',
];

// Cities that we have Popup stores in.
// Adds `Location` to the Explore Menu
export const popupCities = [
  'new-york',
  'austin',
  'dallas'
];

// Same, but for Community pages.
export const communityCities = [
  'austin',
  'denver',
  'washington-dc',
  'boston',
  'new-york'
];

/* eslint-disable no-empty-pattern */
export default function megaMenuInsplore({
  // cities
}: { cities?: string[]}) {
  const links: IMegaMenuColumn[] = [{
    title: 'By Room',
    links: [
      {
        title: 'Living Room',
        url: '/exp/living-room-design-ideas',
      },
      {
        title: 'Dining Room',
        url: '/exp/dining-room-design-ideas',
      },
      {
        title: 'Bedroom',
        url: '/exp/bedroom-design-ideas',
      },
      {
        title: 'Home Office',
        url: '/exp/office-design-ideas',
      },
      {
        title: 'Nursery',
        url: '/exp/nursery-design-ideas',
      },
      {
        title: 'Patio',
        url: '/exp/patio-design-ideas',
      },
      {
        title: 'Entry Way',
        url: '/exp/entry-way-design-ideas',
      },
    ],
  },
  {
    title: 'By Style',
    links: [{
      title: 'Midcentury Modern',
      url: '/exp/midcentury-modern-design-ideas',
    }, {
      title: 'Bohemian',
      url: '/exp/bohemian-design-ideas',
    }, {
      title: 'Farmhouse',
      url: '/exp/farmhouse-design-ideas',
    }, {
      title: 'Traditional',
      url: '/exp/traditional-design-ideas',
    }, {
      title: 'Coastal',
      url: '/exp/coastal-design-ideas',
    }, {
      title: 'Scandinavian',
      url: '/exp/scandinavian-design-ideas',
    }, {
      title: 'Glam',
      url: '/exp/glam-design-ideas',
    }],
  }];

  if (locationNavCities && locationNavCities.length > 0) {
    links.push({
      title: 'By Location',
      links: getIdeasByLocationLinks(locationNavCities),
      twoCols: true
    });
  }

  links.push({
    title: 'Havenly In-Person',
    // @ts-ignore
    text: 'Your perfect Havenly designer, in real life. Available in select markets',
    image: {
      src: 'https://s3.amazonaws.com/static.havenly.com/content/submenu/header-nyc-showroom_2023.jpg',
      link: '/in-person',
    },
  });

  return links;
}

export function getIdeasByLocationLinks(cities?: string[]) {
  if (!cities) {
    return [];
  }
  const locationLinks: ILink[] = sortBy(cities, (city) => city).map((city) => {
    const baseUrl = `/interior-designers/${city}`;
    const cityDisplay = beautifyCityString(city);

    return {
      title: cityDisplay,
      url: baseUrl,
      renderSubPage: ({ handleGoBack }: { handleGoBack: () => void}) => renderSubPage({
        title: `Havenly in ${cityDisplay}`,
        url: baseUrl,
        handleGoBack,
        subNavigationMenu: locationConfig(city)
      })
    };
  });

  locationLinks.push({
    title: 'View All Locations',
    url: '/in-person#our-markets',
  });

  return locationLinks;
}

function renderSubPage(props: ISubPageProps) {
  return (
    <SubPage
      {...props}
    />
  );
}

