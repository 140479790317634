import React from 'react';
import styles from './MegaMenuDesktop.module.scss';
import CurveShape from '../../images/curve-shape-desktop.svg';
import Stars from '../../images/stars.svg';
import NextCircle from '../../images/next-circle.svg';

interface IMegaMenuDesktop {
  links:{
    imageTitle: string;
    imageSrc: string;
    imageText: string;
    textFooter: string;
    imageLink: any;
    links:Object[];
    title:string;
    url:string;
  }[];
}

const renderLink = (link:any) => {
  const classes = `
    ${styles.LinkDetails}
  `;
  return (
    <a
      className={ classes }
      href={ link.url }
      key={ link.title }
    >
      {link.title}
    </a>
  );
};

const MegaMenuDesktop = ({
  links
}:IMegaMenuDesktop) => {
  return (
    <div className={ styles.MegaMenuDesktopContainer }>
      <nav
        className={ `
                  ${styles.MegaMenuDesktop}
                ` }
      >
        <div className={ styles.FirstColumn }>
          <span className={ styles.Title }>
            {links[0]?.title}
          </span>
          {links[0].links && (
            <div className={styles.FirstColumnLinks}>
              {
                links[0].links.map((link, ind) => {
                  if (ind < 7) {
                    return renderLink(link);
                  }
                  return null;
                })
              }
            </div>
          )}
        </div>
        {links.length >= 2 && (

          <div className={styles.SecondColumn}>
            <span className={styles.Title}>
              {links[1].title}
            </span>
            {links[1].links && (
              <div className={styles.SectionLinks}>
                {
                  links[1].links.map((link, ind) => {
                    if (ind < 7) {
                      return renderLink(link);
                    }
                    return null;
                  })
                }
              </div>
            )}
          </div>
        )}
        {links.length >= 3 && (
          <div className={styles.ThirdColumn}>
            <span className={styles.Title}>
              {links[2].title}
            </span>
            {links[2].links && (
              <div className={styles.SectionLinks}>
                {
                  links[2].links.map((link, ind) => {
                    if (ind < 7) {
                      return renderLink(link);
                    }
                    return null;
                  })
                }
              </div>
            )}
          </div>
        )}
        {links.length >= 4 && (
          <>
            <div className={ styles.FourthColumn }>
              {links[3].imageLink && (
                <a className={styles.WrapperImageGroup} href={links[3].imageLink}>
                  {links[3].imageTitle && links[3].imageSrc && (
                    <img
                      alt={links[3].imageTitle}
                      className={styles.ImageRight}
                      src={links[3].imageSrc}
                    />
                  )}
                  <span className={styles.NextCircle}>
                    <p className={styles.ImageTextTitle}>
                      {links[3].imageTitle}
                    </p>
                    <p className={styles.ImageTextContent}>
                      {links[3].imageText}
                    </p>
                    <NextCircle />
                  </span>
                </a>
              )}
            </div>
            {links[3].textFooter && (
            <div className={ styles.wrapperFooter }>
              <CurveShape />
              <p>
                { links[3].textFooter }
                <span className={ styles.Stars }>
                  <Stars />
                </span>
              </p>
            </div>
            )}
          </>
        )}
      </nav>
    </div>
  );
};

export default MegaMenuDesktop;
