import { communityCities, popupCities } from './megaMenuInsplore.config';

interface IMenuItem {
  title: string;
  url: string;
}

export default function getSubNavigationByLocation(
  city: string,
) {
  const baseUrl = `/interior-designers/${city}`;

  const items: IMenuItem[] = [{
    title: 'Team',
    url: `${baseUrl}/team`
  }, {
    title: 'Portfolio',
    url: `${baseUrl}/portfolio`
  }, {
    title: 'Services',
    url: `${baseUrl}/services`
  }, {
    title: 'Reviews',
    url: `${baseUrl}/reviews`
  }, {
    title: 'Cost',
    url: `${baseUrl}/cost`
  }];

  if (popupCities.includes(city)) {
    items.push({
      title: 'Location',
      url: `${baseUrl}/location`
    });
  }

  if (communityCities.includes(city)) {
    items.push({
      title: 'Community',
      url: `${baseUrl}/community`
    });
  }

  return items;
}
