import React from 'react';
import howItWorks from '../../../config/howItWorks.config';
import MegaMenuDesktop, { IMegaMenuColumn } from '../../Desktop/MegaMenu/MegaMenu';
import megaMenuInsplore from '../../../config/megaMenuInsplore.config';
import ShopSubPage from '../SubPages/ShopSubPage/ShopSubPage';
import ImageMegaMenu, { IImageMegaMenuLink } from '../../Desktop/MegaMenu/ImageMegaMenu';

export interface ILink {
  title: string;
  url: string;
  separator?: boolean;
  renderSubPage?: (pageProps: any) => JSX.Element;
}

export interface IMegaTab {
  title: string;
  links: ILink[];
}

export interface INavLink extends ILink {
  shortTitle?: string;
  megaMenu?: IMegaTab[] | IImageMegaMenuLink[] | ILink[] | IMegaMenuColumn[];
  children?: any[];
  mobileOnly?: boolean;
  isPaginated?: boolean;
  renderSubNav?: () => JSX.Element;
}

export const accountLinks: ILink[] = [
  {
    title: 'My Favorites',
    url: '/favorites/products',
  },
  {
    title: 'Orders',
    url: '/orders/history',
  },
  {
    title: 'Credits',
    url: '/credits',
  },
  {
    title: 'Account Details',
    url: '/users/account',
  },
  {
    title: 'Refer & Earn',
    url: '/referral_sources/refer_a_friend',
  },
  {
    title: 'Style Quiz',
    url: '/style-quiz',
  },
  {
    title: 'Logout',
    url: '/logout',
    separator: true,
  },
];

export function shopNavLink(shopNavData: IMegaMenuColumn[]): INavLink {
  return {
    title: 'Shop',
    url: '/shop',
    renderSubNav: () => (<MegaMenuDesktop menuColumns={shopNavData} />),
    megaMenu: shopNavData as IMegaMenuColumn[],
    isPaginated: true,
    renderSubPage: (props: any) => {
      return (
        <ShopSubPage
          shopNavigationMenu={props.shopNavigationMenu}
          handleGoBack={props.handleGoBack}
        />
      );
    },
  };
}

export const customerLinks = (shopNavData: IMegaMenuColumn[]): INavLink[] => {
  return [{
    title: 'How It Works',
    url: '/interior-design-services',
    renderSubNav: () => (<ImageMegaMenu megaMenu={howItWorks} />),
    megaMenu: howItWorks
  },
  {
    title: 'My Projects',
    url: '/rooms',
    children: [
      /\/room\/home\/\d+/,
      /\/room\/shop\/\d+/,
    ]
  },
  {
    title: 'My Boards',
    url: '/home/boards',
  },
  {
    title: 'My Designers',
    url: '/home/designers',
  },
  {
    title: 'Blog',
    url: '/blog/',
  },
  {
    title: 'Portfolio',
    url: '/exp/interior-design-ideas',
    renderSubNav: () => (<MegaMenuDesktop menuColumns={megaMenuInsplore({})} />),
    megaMenu: megaMenuInsplore({}) as IMegaTab[]
  },
  shopNavLink(shopNavData),
  {
    title: 'Account',
    url: '/users/account',
    megaMenu: accountLinks as ILink[],
    mobileOnly: true
  }];
};

export function loggedOutLinks(shopNavData: IMegaMenuColumn[]) {
  return [
    {
      title: 'How It Works',
      url: '/interior-design-services',
      renderSubNav: () => (<ImageMegaMenu megaMenu={howItWorks} />),
      megaMenu: howItWorks
    },
    {
      title: 'Blog',
      url: '/blog/',
    },
    {
      title: 'Portfolio',
      url: '/exp/interior-design-ideas',
      renderSubNav: () => (<MegaMenuDesktop menuColumns={megaMenuInsplore({})} />),
      megaMenu: megaMenuInsplore({}) as IMegaTab[]
    },
    {
      title: 'Gift Cards',
      url: '/gift',
    },
    shopNavLink(shopNavData),
  ];
}
