import { IImageMegaMenuLink } from '../MainHeader/Desktop/MegaMenu/ImageMegaMenu';

const howItWorks: IImageMegaMenuLink[] = [
  {
    title: 'The Details',
    url: '/interior-design-services',
    imageSrc: 'https://s3.amazonaws.com/static.havenly.com/content/submenu/header-details.jpg'
  },
  {
    title: 'Designers',
    url: '/interior-designers',
    imageSrc: 'https://s3.amazonaws.com/static.havenly.com/content/submenu/header-designers.jpg'
  },
  {
    title: 'Style Quiz',
    url: '/interior-design-style-quiz',
    imageSrc: 'https://s3.amazonaws.com/static.havenly.com/content/submenu/header-pricing.jpg'
  },
  {
    title: 'Reviews',
    url: '/reviews',
    imageSrc: 'https://s3.amazonaws.com/static.havenly.com/content/submenu/header-reviews.jpg',
  }
];

export default howItWorks;
