import {
  IShopNavigationMenu,
  IShopNavigationMenuItem
} from '@components/shared/shop/ShopNavigationMenu';
import { useTheme } from '@components/theme';
import { IPrismicImage } from '@lib/prismic/types';
import classNames from 'classnames';
import React, { Fragment, useMemo } from 'react';
import Divider from '@components/shared/Divider/Divider';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import PrismicImage from '@components/shared/images/atoms/Image/PrismicImage';
import breakpoints from '@constants/breakpoints';
import { useRouter } from 'next/router';
import AccordionItem from '@components/shared/Accordion/AccordionItem/AccordionItem';
import { MERCH_GROUP_URL_PREFIX } from '@components/shared/shop/model/MerchGroup';
import { MERCH_GROUP_CATEGORY_URL_PREFIX } from '@components/shared/shop/model/MerchGroupCategory';
import { substringAfterLast } from '@lib/string';
import styles from './ShopSubPage.module.scss';
import ChevronLeftIcon from './assets/Chevron-Left.svg';

SwiperCore.use([Pagination]);

interface IShopSubPageProps {
  shopNavigationMenu?: IShopNavigationMenu[];
  handleGoBack: () => void;
}

const ShopSubPage = ({
  shopNavigationMenu: rootMenuItems,
  handleGoBack
}: IShopSubPageProps) => {
  const router: NextRouter = useRouter();
  const currentSlug = useMemo(
    () => substringAfterLast(router.asPath, '/'),
    [router.asPath]
  );

  const theme = useTheme();

  const images = useMemo(() => {
    return rootMenuItems?.reduce((prev, { option }) => {
      return prev.concat(option ? [
        {
          image: option.image1,
          ctaLabel: option.image1_cta_label,
          ctaUrl: option.image1_cta_link,
        },
        {
          image: option.image2,
          ctaLabel: option.image2_cta_label,
          ctaUrl: option.image2_cta_link,
        }
      ] : []);
    }, [] as Array<{
      image: IPrismicImage;
      ctaLabel: string;
      ctaUrl: string;
    }>).filter((f) => f?.image?.url);
  }, [rootMenuItems]);

  const isFeatured = (item: IShopNavigationMenuItem) => item.slug === 'feature';

  const hasActiveSlug = (item: IShopNavigationMenuItem): boolean => {
    if (!isFeatured(item) && item.categoryChildren && item.categoryChildren?.length > 0) {
      return item.categoryChildren?.some(
        (childCat) => childCat.slug === currentSlug || hasActiveSlug(childCat)
      );
    } else {
      return (
        item.children &&
        item.children.some((child) => child.slug === currentSlug)
      );
    }
  };

  return (
    <div className={classNames(styles.ShopSubPage, theme.styles.Container)}>
      <div className={styles.ListWrapper}>
        <button
          type="button"
          className={classNames(
            theme.styles.Title2,
            theme.styles.Core4,
            styles.Header
          )}
          onClick={handleGoBack}
        >
          <ChevronLeftIcon />
          <span>Back</span>
        </button>

        <div className={styles.CategoryChildrenList}>
          <div className={styles.ShopHome}>
            <a href={`/shop`}>Shop Home</a>
          </div>
          <Divider />
          {
            rootMenuItems?.map(({ item, url: itemUrl }) => (
              <AccordionItem
                key={itemUrl}
                className={classNames(
                  styles.MerchGroupCategoryAccordion,
                  { [styles.IsHighLighted]: isFeatured(item) },
                  { [styles.isSelected]: item.selected }
                )}
                dividerClassName={styles.AccordionDivider}
                headerButtonClass={styles.AccordionHeader}
                iconClassName={styles.AccordionIcon}
                title={item.title}
                showDivider
                showIcon
                openedInitialState={hasActiveSlug(item)}
              >
                <div className={styles.MerchGroupCategoryList}>
                  {item.categoryChildren && item.categoryChildren.length > 0 ? (
                    <>
                      {item.categoryChildren.map((categoryChild) => {
                        return (
                          <AccordionItem
                            key={`category/${categoryChild.slug}`}
                            className={classNames(
                              styles.SubCategoryAccordion, {
                                [styles.isSelected]: categoryChild.selected
                              }
                            )}
                            title={categoryChild.title}
                            openedInitialState={hasActiveSlug(categoryChild)}
                          >
                            <ul className={styles.CategoryChildren}>
                              <li>
                                <a
                                  className={classNames(
                                    styles.MerchGroup,
                                    styles.IsSubCategory
                                  )}
                                  href={`${MERCH_GROUP_CATEGORY_URL_PREFIX}/${categoryChild.slug}`}
                                >
                                  View All
                                </a>
                              </li>
                              {categoryChild.children.map((child) => (
                                <li key={`merch-group/${child.slug}`}>
                                  <a
                                    href={`${MERCH_GROUP_URL_PREFIX}/${child.slug}`}
                                    className={classNames(
                                      styles.MerchGroup,
                                      styles.IsSubCategory,
                                      { [styles.isSelected]: child.selected }
                                    )}
                                  >
                                    {child.title}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </AccordionItem>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {!isFeatured(item) && (
                        <a
                          href={`${MERCH_GROUP_CATEGORY_URL_PREFIX}/${item.slug}`}
                          className={classNames(styles.MerchGroup)}
                        >
                          View All
                        </a>
                      )}
                      {item.children.map((child) => {
                        const childType = '__typename' in child ? (child as any).__typename : 'unknown';
                        return (
                          <Fragment key={`merch-group/${child.slug}`}>
                            {child.title !== 'All Rugs' && (
                              <div className={styles.MerchGroupWrapper}>
                                {/* Hiding All Rugs until we delete this merch group */}
                                <a
                                  href={
                                      childType === 'MerchGroupModel'
                                        ? `${MERCH_GROUP_URL_PREFIX}/${child.slug}`
                                        : `/shop/${child.slug}`
                                    }
                                  className={
                                      classNames(styles.MerchGroup, {
                                        [styles.isSelected]: (
                                          child.selected || child.slug === currentSlug
                                        )
                                      })
                                    }
                                >
                                  {child.title}
                                </a>
                              </div>
                            )}
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                </div>
              </AccordionItem>
            ))
          }
        </div>
      </div>

      <div className={styles.CarouselWrapper}>
        <div className={styles.Pagination} />
        <Swiper
          className={styles.Carousel}
          slidesPerView={1}
          spaceBetween={0}
          pagination={{
            type: 'bullets',
            el: `.${styles.Pagination}`,
            bulletClass: styles.Bullet,
            bulletActiveClass: styles.BulletActive,
          }}
        >
          {images?.map(({ image, ctaLabel, ctaUrl }, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlide key={idx}>
              <a href={ctaUrl} role="button" className={styles.ImageWrapper}>
                <PrismicImage
                  className={styles.Image}
                  lazy={false}
                  image={image}
                  breakpoints={{
                    mobile: breakpoints.desktop,
                  }}
                />
                <p
                  className={classNames(theme.styles.Title2, styles.ImageLabel)}
                >
                  {ctaLabel}
                </p>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ShopSubPage;
