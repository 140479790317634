import { ApolloError, useMutation } from '@apollo/client';
import { useContext } from 'react';

import { LocalCartContext } from '@context/LocalCart/LocalCartContext';
import MERGE_LOCAL_CART_INTO_USER_CART, {
  IMergeLocalCartIntoUserCartData
} from '@graphql/User/mutations/merge-local-cart-into-user-cart';
import CART_QUERY from '@graphql/User/queries/get-cart-for-user.graphql';
import logger from '@lib/logger';

const log = logger('useMergeLocalCartIntoUserCart');

export default function useMergeLocalCartIntoUserCart(): [
  () => Promise<IMergeLocalCartIntoUserCartData | void>,
  {
    localCartInitialized: boolean;
    error: ApolloError | undefined;
    loading: boolean;
    data: IMergeLocalCartIntoUserCartData | null | undefined;
  }
  ] {
  const [mergeCart, { error, loading, data }] =
    useMutation<IMergeLocalCartIntoUserCartData>(MERGE_LOCAL_CART_INTO_USER_CART);
  const {
    initialized,
    localCart,
    localSavedForLaterProducts,
    cleanLocalCart
  } = useContext(LocalCartContext);

  const handleMergeCart = async () => {
    if (!initialized) {
      log.warn('#handleMergeCart - called before local cart context initialized from local storage');

      return;
    }
    if (
      (localCart && localCart.length > 0) ||
      (localSavedForLaterProducts && localSavedForLaterProducts.length > 0)
    ) {
      const variables: any = {};

      if (localCart && localCart.length > 0) {
        variables.cartProducts = localCart.map((cartItem) => {
          return {
            quantity: cartItem.quantity,
            vendorVariantId: cartItem.vendorVariant.id
          };
        });
      }

      if (localSavedForLaterProducts && localSavedForLaterProducts.length > 0) {
        variables.savedForLaterProducts = localSavedForLaterProducts.map((item) => {
          return {
            quantity: item.quantity,
            vendorVariantId: item.vendorVariant.id
          };
        });
      }

      try {
        await mergeCart({
          variables,
          refetchQueries: [
            {
              query: CART_QUERY
            }
          ]
        });
        cleanLocalCart();
      } catch (err) {
        logger('mergeLocalCartIntoUserCartData').error({ err });
      }
    }
  };

  return [
    handleMergeCart,
    {
      localCartInitialized: initialized,
      error,
      loading,
      data
    },
  ];
}
