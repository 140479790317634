import React from 'react';
import { useRouter } from 'next/router';
import styles from './Nav.module.scss';
import { hashCode } from '../../../../../lib/string';
import NavDropDownMenu from './NavDropDownMenu';
import NavLink from './NavLink';
import NavMegaMenuLink from './NavMegaMenuLink';
import TaxonomyCategoryDropDown from './TaxonomyCategoryDropDown';

const getNavStyles = ({ isNavOpen, className }) => `
  ${styles.Nav}
  ${isNavOpen ? styles.isOpen : ''}
  ${className || ''}
`;

const Nav = (props) => {
  const {
    onClickNavItem,
    links,
    mobileLoginSignup,
    mobileHelpLink,
    taxonomyCategories,
    shopShopMegaMenu
  } = props;

  const { asPath: currentUrl } = useRouter();

  return (
    <nav
      className={ getNavStyles(props) }
      data-test="nav-mobile"
    >
      {
        links.map((link) => {
          if (link.megaMenu && (shopShopMegaMenu || link.url !== '/shop')) {
            return (
              <NavMegaMenuLink
                key={hashCode(link.title)}
                {...props}
                link={link}
                currentUrl={currentUrl}
              />
            );
          }

          return link.links
            ? <NavDropDownMenu key={hashCode(link.title)} {...props} link={link} />
            : <NavLink key={hashCode(link.title)} {...props} link={link} currentUrl={currentUrl} />;
        })
      }

      {taxonomyCategories && (
        <TaxonomyCategoryDropDown
          taxonomyCategories={taxonomyCategories}
          currentUrl={currentUrl}
        />
      )}

      {mobileLoginSignup && (
        <div className={ styles.MobilePostNavItem }>
          <a
            href="/users/login"
            onClick={ () => { onClickNavItem('Login'); } }
          >
            Login
          </a>
          <span className={ styles.Pipe }>|</span>
          <a
            href="/users/add"
            onClick={ () => { onClickNavItem('Sign up'); } }
          >
            Sign up
          </a>
        </div>
      )}

      {mobileHelpLink && (
        <div className={ styles.MobilePostNavItem }>
          <a
            href="https://havenly.kustomer.help/"
            onClick={ () => { onClickNavItem('Help'); } }
          >
            Help
          </a>
          <span className={ styles.Pipe }>|</span>
          <a
            href="tel:18889783152"
            onClick={ () => { onClickNavItem('888.978.3152'); } }
          >
            888.978.3152
          </a>
        </div>
      )}
    </nav>
  );
};

export default Nav;
