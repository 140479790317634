/* istanbul ignore file */

export default function formatCost(cost: number): string {
  let formattedCost = Math.abs(cost).toFixed(2);

  // from http://stackoverflow.com/a/14467460
  formattedCost = formattedCost.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');

  formattedCost = formattedCost.replace('.00', '');
  formattedCost = `$${formattedCost}`;

  if (cost < 0) formattedCost = `-${formattedCost}`;
  return formattedCost;
}
