import React, { useState } from 'react';
import styles from './Nav.module.scss';
import headerStyles from '../Header.module.scss';
import MegaMenuDesktop from '../MegaMenu/MegaMenuDesktop';
import NavLink from './NavLink';
import MegaMenuMobile from '../MegaMenu/MegaMenuMobile';
import If from '../../../conditional/If/If';

const NavMegaMenuLink = (props) => {
  const {
    link,
    parentTitle,
    currentUrl,
    onClickNavItem,
  } = props;

  const classes = `
    ${parentTitle ? styles.SubLink : styles.Link}
    ${headerStyles.MobileOnly}
  `;

  const [currentMegaMenu, setCurrentMegaMenu] = useState('');

  return (
    <div
      key={`${link.title}-MegaMenu`}
      className={`${styles.WrapperMegaMenu} ${link.title === 'Shop'
        && currentUrl?.indexOf('/shop') !== -1 && headerStyles.HideMobile}`}
    >
      <button
        type={'button'}
        className={ classes }
        key={ link.title }
        onClick={ () => setCurrentMegaMenu(link.title) }
      >
        {link.title}
      </button>
      <If condition={currentMegaMenu === link.title}>
        <MegaMenuMobile
          className={headerStyles.MobileOnly}
          currentUrl={currentUrl}
          links={link.megaMenu}
          closeMegaMenu={() => setCurrentMegaMenu('')}
        />
      </If>

      <div className={`
        ${headerStyles.HideMobile}
        ${styles.WrapperMegaMenuDesktop}
      `}
      >
        <NavLink
          link={link}
          onClickNavItem={onClickNavItem}
          currentUrl={currentUrl}
          parentTitle={parentTitle}
        />
        <MegaMenuDesktop
          links={link.megaMenu}
        />
      </div>
    </div>
  );
};

export default NavMegaMenuLink;
