import Account from '../Account/Account';
import Cart from '../Cart/Cart';
import Logo from '../Logo/Logo';
import Messaging from '../Messaging/Messaging';
import Nav from '../Nav/Nav';
import Search from '../Search/Search';

import headerStyles from '../Header.module.scss';
import logoStyles from '../Logo/Logo.module.scss';
import megaMenuInsplore from './megaMenuInsplore.config';
import { IConfigOptions } from './i-config';

export default (opts: IConfigOptions) => {
  return [{
    component: Search,
    key: 'Search-Mobile',
    props: {
      className: headerStyles.LoggedInSearchMobile,
    },
  },
  {
    component: Logo,
    key: 'Logo',
    props: {
      className: logoStyles.CustomerLogo,
    },
  },
  {
    component: Nav,
    key: 'Nav',
    props: {
      className: headerStyles.Center,
      mobileHelpLink: true,
      links: [
        {
          title: 'Portfolio',
          url: '/exp/interior-design-ideas',
          megaMenu: megaMenuInsplore(opts)
        },
        {
          title: 'My Boards',
          url: '/home/boards',
        },
        {
          title: 'My Designers',
          url: '/home/designers',
        },
        {
          title: 'My Projects',
          url: '/rooms',
          children: [
            /\/room\/home\/\d+/,
            /\/room\/shop\/\d+/,
          ]
        },
        {
          title: 'Blog',
          url: '/blog/',
        },
        {
          title: 'Account',
          url: '/users/account',
          mobileOnly: true,
          links: [
            {
              title: 'My Favorites',
              url: '/favorites/products',
            },
            {
              title: 'Orders',
              url: '/orders/history',
            },
            {
              title: 'Credits',
              url: '/credits',
            },
            {
              title: 'Account Details',
              url: '/users/account',
            },
            {
              title: 'Refer & Earn',
              url: '/referral_sources/refer_a_friend',
            },
            {
              title: 'Style Quiz',
              url: '/style-quiz',
            },
            {
              title: 'Logout',
              url: '/logout',
            },
          ],
        },
      ],
    },
  },
  {
    component: Account,
    key: 'Account',
    props: {
      className: headerStyles.RightElementMargin,
      links: [
        {
          title: 'My Favorites',
          url: '/favorites/products',
        },
        {
          title: 'Orders',
          url: '/orders/history',
        },
        {
          title: 'Credits',
          url: '/credits',
        },
        {
          title: 'Account Details',
          url: '/users/account',
        },
        {
          title: 'Refer & Earn',
          url: '/referral_sources/refer_a_friend',
        },
        {
          title: 'Style Quiz',
          url: '/style-quiz',
        },
        {
          title: 'Logout',
          url: '/logout',
          separator: true,
        },
      ],
    },
  },
  {
    component: Messaging,
    key: 'Messaging',
    props: {
      className: headerStyles.RightElementMargin,
    },
  },
  {
    component: Cart,
    key: 'Cart',
  },
  {
    component: Search,
    key: 'Search-Desktop',
    props: {
      className: headerStyles.LoggedInSearchDesktop,
    },
  }];
};
